<script setup>
import ProductLabel from "~/components/molecules/ProductLabel.vue";

const props = defineProps({
  labels: Array,
  right: {
    type: Boolean,
    default: false
  },
  rightDirection: {
    type: Boolean,
    default: false
  }
})
</script>

<template>
  <div class="labels g-4" style="position: absolute;" :style="{alignItems:right ? 'flex-end':false}">
    <ProductLabel v-for="label in props.labels" :color="label.color"
                  :absolute="false"
                  :right-label="rightDirection && label.energy_efficiency"
                  :energy-efficiency="label.energy_efficiency"
                  :name="label.name"
                  :background="label.background"/>
  </div>
</template>

<style scoped lang="scss">

</style>
